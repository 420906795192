import type { DictionaryItemCanonicalName } from '@utils/dictionaries';
import type { FlattenedDictionaries } from '@volvo-cars/content-delivery-client/dictionaries/types';
import { createContext } from 'react';

export type DictionariesContextType = {
  dictionaries: FlattenedDictionaries;
  getDictionaryItemByName: (
    dictionaryName: DictionaryItemCanonicalName,
  ) => string;
};

export const DictionariesContext = createContext<DictionariesContextType>({
  dictionaries: {},
  getDictionaryItemByName: () => '',
});
