import { captureException } from '@sentry/nextjs';

type LogErrorInput = {
  culprit: string;
  message: string;
};

export function logError(error: LogErrorInput) {
  const errorInstance =
    error instanceof Error ? error : new Error(error.message);

  errorInstance.name = error.culprit || errorInstance.name;
  captureException(errorInstance);
}

export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) return error.message;
  return String(error);
}
