import {
  fallbackMasterDictionaries,
  isItemFallbackCanonicalName,
} from '@utils/dictionaries';
import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import type { FlattenedDictionaries } from '@volvo-cars/content-delivery-client/dictionaries/types';

export function dictionaryItemsCanonicalName(
  dictionaryItem: FlattenedDictionaries,
) {
  const dictionaryItemKeys = Object.keys(dictionaryItem);
  const initDictionaries: FlattenedDictionaries = {};
  return dictionaryItemKeys.reduce((prev, curr) => {
    prev[curr] = curr;
    return prev;
  }, initDictionaries);
}

export function dictionaryItemsLocale(locale: string) {
  return locale === 'en-001' ? 'en' : locale;
}

export function collectDictionaryItemFromList(
  item: string,
  dictionaryItems: FlattenedDictionaries,
) {
  if (!dictionaryItems[item]) {
    reportDetailedErrorMessage({
      culprit: 'collectDictionaryItemFromList',
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        message: `Dictionary item "${item}" is missing`,
      },
    });

    if (isItemFallbackCanonicalName(item)) {
      return fallbackMasterDictionaries[item];
    }

    reportDetailedErrorMessage({
      culprit: 'collectDictionaryItemFromList',
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        message: `Dictionary item "${item}" is missing on fallback dictionaries`,
      },
    });

    return '';
  }

  return dictionaryItems[item] ?? '';
}
