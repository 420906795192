'use client';

import { DictionariesContext } from '@contexts/dictionaries/dictionaries.context';
import { dictionaryItemsCanonicalName } from '@utils/content-delivery-client/utils';
import {
  type DictionaryItemCanonicalName,
  fallbackMasterDictionaries,
} from '@utils/dictionaries';
import type { FlattenedDictionaries } from '@volvo-cars/content-delivery-client/dictionaries/types';
import type React from 'react';
import { useContext } from 'react';

export function useDictionaries() {
  return useContext(DictionariesContext);
}

/**
 * @param children React child nodes
 * @param dictionaries The dictionaries to use
 * @returns A React Context Provider for dictionaries, enables the use of useDictionaries() hook
 */
export function DictionariesProvider({
  children,
  dictionaries,
  isDictionariesModeActive,
  sharedDictionaries,
}: React.PropsWithChildren<{
  dictionaries: FlattenedDictionaries;
  isDictionariesModeActive: boolean;
  sharedDictionaries: FlattenedDictionaries;
}>) {
  const mergedDictionaries: FlattenedDictionaries = {
    ...sharedDictionaries,
    ...dictionaries,
  };

  const allDictionaries = !isDictionariesModeActive
    ? mergedDictionaries
    : dictionaryItemsCanonicalName(mergedDictionaries);

  /**
   * @param dictionaryName The name of the dictionary to get, e.g. "Test.test"
   * @returns A translated string from the dictionary, or the dictionary name enclosed in brackets if not found.
   */
  const getDictionaryItemByName = (
    dictionaryName: DictionaryItemCanonicalName,
  ) => {
    if (isDictionariesModeActive) {
      return dictionaryName;
    }

    return (
      allDictionaries[dictionaryName] ||
      fallbackMasterDictionaries[dictionaryName] ||
      `[${dictionaryName}]`
    );
  };

  return (
    <DictionariesContext.Provider
      value={{
        dictionaries: allDictionaries,
        getDictionaryItemByName,
      }}
    >
      {children}
    </DictionariesContext.Provider>
  );
}
