import type { QueryError } from '@utils/gql/graphql-fetch.utils';
import { logError } from './error-logging';

export enum errorCategories {
  CONTENT_DELIVERY = 'CONTENT_DELIVERY',
  FEATURE_FLAG = 'FEATURE_FLAG',
  FETCH = 'FETCH',
  GENERAL = 'GENERAL',
  GRAPH = 'GRAPH',
}

export type ErrorCategory = keyof typeof errorCategories;

export type ErrorMessageDetails = {
  queryError?: QueryError;
  additionalInformation?: Record<string, string | undefined>[];
  fetchUrl?: string;
  message?: string;
  missingParts?: string[];
  success?: boolean;
};

export function reportDetailedErrorMessage({
  culprit,
  errorCategory,
  details,
}: {
  culprit: string;
  errorCategory: ErrorCategory;
  details: ErrorMessageDetails;
}) {
  switch (errorCategory) {
    case errorCategories.CONTENT_DELIVERY: {
      const message = contentDeliveryClientError(details);
      logError({ culprit, message });
      return;
    }
    case errorCategories.GRAPH: {
      const message = graphError(details);
      logError({ culprit, message });
      return;
    }
    case errorCategories.FETCH: {
      const message = fetchError(details);
      logError({ culprit, message });
      return;
    }
    case errorCategories.FEATURE_FLAG:
      logError({ culprit, message: `Feature Flag Error: ${details.message}` });
      return;
    case errorCategories.GENERAL:
      logError({ culprit, message: `General Error: ${details.message}` });
      return;
    default:
      logError({ culprit, message: 'Unspecified error occurred' });
      return;
  }
}

function contentDeliveryClientError(errorDetails: ErrorMessageDetails) {
  const messageTitle = 'Content Delivery Client Error';
  let messageDescription = errorDetails.message
    ? `${messageTitle}: ${errorDetails.message}`
    : messageTitle;

  if (!errorDetails?.missingParts?.length) {
    return messageDescription;
  }

  if (errorDetails.missingParts?.length === 1) {
    messageDescription = `${messageDescription} \n
      Missing field: ${errorDetails.missingParts[0]}
    `;
    return messageDescription;
  }

  const lastMissingField = errorDetails.missingParts.pop();
  const missingFieldsText = `Missing fields: ${errorDetails.missingParts.join(', ')} and/or ${lastMissingField}`;
  messageDescription = `${messageDescription} \n
      ${missingFieldsText}
    `;
  return messageDescription;
}

function graphError(errorDetails: ErrorMessageDetails) {
  const messageTitle = 'Graph Error';
  const messageDescription = `${messageTitle}: ${errorDetails.message}`;
  const detailedMessage = `${messageDescription} \n
    Additional Information: ${errorDetails?.additionalInformation?.join('\n')}  \n
    Query Error: ${errorDetails?.queryError?.join('\n')}
  `;

  return !errorDetails.additionalInformation?.length
    ? messageDescription
    : detailedMessage;
}

function fetchError(errorDetails: ErrorMessageDetails) {
  const messageTitle = 'Fetch Error';
  const messageDescription = `${messageTitle}: ${errorDetails.message} \n
    Fetch URL: ${errorDetails?.fetchUrl}
  `;

  return messageDescription;
}
