// Images
export const FALLBACK_IRONMARK_WHITE_192 =
  'https://www.volvocars.com/static/shared/images/favicons/favicon-maskable-light-ui-192x192.png';
export const FALLBACK_IRONMARK_512 =
  'https://www.volvocars.com/static/shared/images/favicons/favicon-maskable-light-ui-512x512.png';
export const FALLBACK_WORDMARK_IMAGE =
  'https://www.volvocars.com/static/shared/images/volvo_wordmark-black-wb.png';
export const FALLBACK_IRONMARK_BLACK_192 =
  'https://www.volvocars.com/static/shared/images/favicons/favicon-192x192.v2.png';

// Product Handles
export const ProductHandle = {
  extendedWarranty: 'extended-warranty',
  chargingCableBag: 'charging-cable-bag',
  chargingCableMode3Type2: 'charging-cable-mode-3-type-2',
  performanceMode: 'performance-mode',
  pilotAssist: 'pilot-assist',
  realTimeEnergyMeter: 'real-time-energy-meter',
  servicePlan: 'service-plan',
  volvoWallboxWithCable: 'volvo-wallbox-with-cable',
  volvoWallboxWithSocket: 'volvo-wallbox-with-socket',
  volvoWallboxWithSocketMid: 'volvo-wallbox-with-socket-mid',
} as const;

export type ProductHandleType =
  (typeof ProductHandle)[keyof typeof ProductHandle];
